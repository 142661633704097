import React, { Component } from 'react';
import Link from 'gatsby-link';

class FAQCard extends Component {

  render() {
    return (
      <div className="card" style={{marginTop: 24}}>
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="title is-4" style={{ color: '#118cff' }}>{this.props.question}</p>
            </div>
          </div>

          <div className="content" dangerouslySetInnerHTML={{__html: this.props.answer}}>

            {/* <br /> */}
          </div>
        </div>
      </div>
    );
  };
};

export default FAQCard;
