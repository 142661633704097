import React, { Component } from "react";
import Link from "gatsby-link";
import TwitterCard from "../TwitterCard/TwitterCard";

const content1 =
  "We'll help minimize or avoid construction change orders due to vague or inadequate design parameters.";
const content2 =
  "We specify even small details to help the construction team get it right the first time and avoid rework.";
const content3 =
  "We address all AHJ and code concerns during our design process to avoid permitting and inspection schedule delays.";

class OurPromise extends Component {
  render() {
    return (
      <section className="bd-tws-home section-light" style={{ paddingTop: 40, paddingBottom: 80 }}>
        <div className="container">
          <h1 className="has-text-centered title" style={{ paddingBottom: 25 }}>
            What Sets Us Apart
          </h1>
          <h2 className="subtitle" style={{ marginTop: 10, color: "#434a57" }}>
            Our detailed design packages reduce overall project costs in multiple ways:
          </h2>
          <div className="columns">
            <div className="column is-4">
              <TwitterCard
                title="Thorough Planning"
                content={content1}
                textColor="rgb(67, 74, 87)"
              />
            </div>
            <div className="column is-4">
              <TwitterCard title="High Quality" content={content2} textColor="rgb(67, 74, 87)" />
            </div>
            <div className="column is-4">
              <TwitterCard title="Professional" content={content3} textColor="rgb(67, 74, 87)" />
            </div>
          </div>
          <br />
          <h2 className="subtitle" style={{ marginTop: 10, color: "#434a57" }}>
            We listen to our clients and are guided by experience and determination to turn your
            needs into a complete construction package that can be built both within budget and on
            schedule.
          </h2>
        </div>
      </section>
    );
  }
}

export default OurPromise;
