import React, { Component } from "react";
import Link from "gatsby-link";
import FAQ from "../FAQ/FAQ";
import ServiceEngineering from "../ServiceEngineering/ServiceEngineering";
import ServiceControls from "../ServiceControls/ServiceControls";
import ServiceInstrumentation from "../ServiceInstrumentation/ServiceInstrumentation";
import ServiceConsulting from "../ServiceConsulting/ServiceConsulting";
import ServiceMechanical from "../ServiceMechanical/ServiceMechanical";
import ServiceCivil from "../ServiceCivil/ServiceCivil";
import ServiceArchitecture from "../ServiceArchitecture/ServiceArchitecture";

class CompanyOverview extends Component {
  render() {
    return (
      <section className="is-medium is-bold" style={{ backgroundColor: "#FFF" }}>
        <div className="hero-body">
          <div className="container">
            <h1 className="has-text-centered title" style={{ color: "#434a57" }}>
              Company Overview
            </h1>
            <h2 className="subtitle" style={{ marginTop: 10, color: "#434a57" }}>
              <div>
                <p>
                  Sverdrup Engineering Services (SES) is a multi-disciplinary engineering firm whose
                  focus is electrical and civil engineering for projects falling into six major
                  areas:
                </p>
                <br />
                <ul style={{ display: "list-item", listStyleType: "disc", marginLeft: 50 }}>
                  <li>Solar design </li>
                  <li>Mining & Industrial</li>
                  <li>Electric Vehicle (EV) Charging</li>
                  <li>Power Distribution</li>
                  <li>Power Protection & Control l(P&C)</li>
                  <li>Commercial</li>
                </ul>
                <br />
                <p>
                  We partner closely with our clients to deliver practical, cost-effective solutions
                  for projects that ultimately exceed their expectations. Our detailed engineering,
                  employment of the latest industry innovations, combined with our personal
                  dedication drives our clients’ visions and priorities to reality. We serve clients
                  throughout the world from our Arizona headquarters and virtual offices nationwide.
                </p>
                <br />

                <p>
                  We serve clients throughout the world from our Arizona headquarters and virtual
                  offices nationwide.
                </p>
              </div>
            </h2>
          </div>
        </div>
      </section>
    );
  }
}

export default CompanyOverview;
