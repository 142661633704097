import React, { useState, useEffect, useRef } from "react";
import Content from "../Content/Content";

const $ = require("jquery");
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

const ContactShort = (props) => {
  // const [email, setEmail] = useState('')
  // const [name, setName] = useState('')
  // const [company, setCompany] = useState('')
  // const [message, setMessage] = useState('')
  const [emailClass, setEmailClass] = useState('')
  const [nameClass, setNameClass] = useState('')
  const [companyClass, setCompanyClass] = useState('')
  const [messageClass, setMessageClass] = useState('')

  const recaptchaRef = useRef();

  function _handleNameChange(e) {
    if (e.target.value != "") {
      setNameClass('')
    }
  }

  function _handleEmailChange(e) {
    if (e.target.value != "") {
      setEmailClass('')
    }
  }

  function _handleCompanyChange(e) {
    if (e.target.value != "") {
      setCompanyClass('')
    }
  }

  function _handleMessageChange(e) {
    if (e.target.value != "") {
      setMessageClass('')
    }
  }

  function clearContactForm() {
    ["name", "email", "company", "message"].forEach((formElement) => {
      var element = document.getElementById("contact-" + formElement);
      element.value = "";
    });
  }

  function submitContactForm() {
    var invalid = false;
    ["name", "email", "company", "message"].forEach((formElement) => {
      var element = document.getElementById("contact-" + formElement);
      if (element.value == "") {
        invalid = true;
        switch (formElement) {
          case "name":
            setNameClass("is-danger")
            break;
          case "email":
            setEmailClass("is-danger")
            break;
          case "company":
            setCompanyClass("is-danger")
            break;
          case "message":
            setMessageClass("is-danger")
            break;
        }
      } else {
        switch (formElement) {
          case "name":
            setNameClass('')
            break;
          case "email":
            setEmailClass('')
            break;
          case "company":
            setCompanyClass('')
            break;
          case "message":
            setMessageClass('')
            break;
        }
      }
    });
    if (invalid) {
      return;
    }
    $.ajax({
      url: "https://hooks.zapier.com/hooks/catch/3433564/xmp1n0/",
      type: "post",
      data: $("#contact-form").serialize(),
      success: function() {
        clearContactForm();
        swal("Thanks!", "We'll get back to you shortly.", "success");
      },
    });
  }

  async function onSubmitWithReCAPTCHA (e) {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();

    // apply to form data
    submitContactForm();
  }

  useEffect(() => {
    $("#contact-form").submit(onSubmitWithReCAPTCHA);
  }, [])

    return (
      <div style={{ paddingTop: 15 }}>
        <h2 className="is-size-2">{props.title}</h2>
        <form id="contact-form">
        <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Lf8s-AZAAAAAMlZPcheslYI5PwfLOJ_vOLhk8jn"
      />
          {/*Create columns so input is 1/2 width. set padding to 0 so theres no adding padding. Set minWidth 350 b/c overall wrapping happens quickly ??*/}
          <div className="columns" style={{ width: 300, margin: "0 auto" }}>
            <div className="column" style={{ paddingBottom: 0 }}>
              <div className="field">
                <div className="control">
                  <input
                    id="contact-name"
                    aria-label="Your name"
                    name="name"
                    className={"input " + nameClass}
                    type="text"
                    placeholder="Your name"
                    data-lpignore="true"
                    onChange={_handleNameChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns" style={{ width: 300, margin: "0 auto" }}>
            <div className="column" style={{ paddingBottom: 0 }}>
              <div className="field">
                <div className="control">
                  <input
                    id="contact-email"
                    aria-label="Work email"
                    className={"input " + emailClass}
                    type="email"
                    name="email"
                    placeholder="Work email"
                    data-lpignore="true"
                    onChange={_handleEmailChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="columns" style={{ width: 300, margin: "0 auto" }}>
            <div className="column" style={{ paddingBottom: 0 }}>
              <div className="field">
                <div className="control">
                  <input
                    id="contact-company"
                    aria-label="Company Name"
                    className={"input " + companyClass}
                    type="company"
                    name="company"
                    placeholder="Company name"
                    data-lpignore="true"
                    onChange={_handleCompanyChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="columns" style={{ width: 500, margin: "0 auto" }}>
            <div className="column" style={{ paddingBottom: 10 }}>
              <div className="field">
                <div className="control">
                  <textarea
                    id="contact-message"
                    aria-label="Message"
                    className={"textarea " + messageClass}
                    type="message"
                    name="message"
                    placeholder="Message"
                    data-lpignore="true"
                    onChange={_handleMessageChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="field-body" style={{ width: 300, margin: "0 auto" }}>
            <div className="field">
              <div className="control">
                <input
                  style={{ width: "100%" }}
                  type="submit"
                  value="Get in touch!"
                  className="button"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    )
}

export default ContactShort;
