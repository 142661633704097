import React, { Component } from "react";
import Link from "gatsby-link";
import FAQ from "../FAQ/FAQ";

class ServiceEngineering extends Component {
  render() {
    //<img />  TODO: Image on left, text on right
    return (
      <div
        className="service"
        data-content="service-industrial"
        style={{ paddingTop: 5, width: "100%" }}
      >
        <div
          className="service-inner columns is-centered has-text-centered"
          data-content="service-industrial"
          style={{ width: "100%", flexDirection: "row", display: "flex" }}
        >
          <div className="column" data-content="service-industrial">
            <i
              className="fa fa-pencil-ruler"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <p className="is-size-3">Design</p>
            <ul>
              <li>Panel Layout and Design</li>
              <li>Electrical Design (Low, Medium and High Voltage)</li>
              <li>HMI Design</li>
              <li>Control Block Diagrams</li>
              <li>Control Loop Drawings</li>
              <li>PLC Panel Drawings</li>
              <li>Instrumentation System Design</li>
              <li>Wireless Communication and Networks</li>
              <li>SCADA Design</li>
            </ul>
          </div>
          <div className="column" data-content="service-industrial">
            <i
              className="fa fa-laptop"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <p className="is-size-3">Controls</p>
            <ul>
              <li>Motor Controls</li>
              <li>
                PLC Programming (Allen-Bradley, Siemens, Modicon, LabVIEW)
              </li>
              <li>Data Collection</li>
              <li>Database Management</li>
              <li>SCADA Programming</li>
              <li>SCADA Implementation</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceEngineering;
