import React, { Component } from "react";
import Link from "gatsby-link";
import FAQ from "../FAQ/FAQ";

class ServiceMechanical extends Component {
  render() {
    //<img />  TODO: Image on left, text on right
    return (
      <div
        className="service"
        data-content="service-mechanical"
        style={{ paddingTop: 5, width: "100%" }}
      >
        <div
          className="service-inner columns is-centered has-text-centered"
          data-content="service-mechanical"
          style={{ width: "100%", flexDirection: "row", display: "flex" }}
        >
          <div className="column" data-content="service-mechanical">
            <i
              className="fa fa-pencil-ruler"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <p className="is-size-3">Design</p>
            <ul>
              <li>General Arrangements (GA)</li>
              <li>Process Flow Diagrams (PFD)</li>
              <li>Piping & Instrument Diagrams (P&ID)</li>
              <li>Mechanical Layout Drawings</li>
              <li>Piping Layout Drawings</li>
              <li>Conveyor Design Drawings</li>
              <li>Tank Design Drawings</li>
            </ul>
          </div>
          <div className="column" data-content="service-mechanical">
            <i
              className="fa fa-tools"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <p className="is-size-3">Materials</p>
            <ul>
              <li>Material Handling</li>
              <li>Dust Collection</li>
              <li>Crushing, Grinding, and Classification</li>
              <li>Process Pumping and Piping</li>
              <li>Overland Pipe</li>
              <li>Plumbing</li>
              <li>HVAC</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceMechanical;
