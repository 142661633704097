import React, { Component } from "react";
import Link from "gatsby-link";
import FAQ from "../FAQ/FAQ";
import ServiceEngineering from "../ServiceEngineering/ServiceEngineering";
import ServiceControls from "../ServiceControls/ServiceControls";
import ServiceInstrumentation from "../ServiceInstrumentation/ServiceInstrumentation";
import ServiceConsulting from "../ServiceConsulting/ServiceConsulting";
import ServiceMechanical from "../ServiceMechanical/ServiceMechanical";
import ServiceCivil from "../ServiceCivil/ServiceCivil";
import ServiceArchitecture from "../ServiceArchitecture/ServiceArchitecture";

const power = "Sverdrup Engineering ";

const solar = "";

const mines = "";

const parking = "";

// const serviceRenewable = {
//   Design: ['Single Line Diagrams', 'Plan View Drawings', 'Elementary/Interconnection Schematics', 'Three Line Diagrams'],
//   Studies: ['Short Circuit Analysis', 'Arc Flash Analysis', 'Device Protection Coordination Study', 'Load Flow Analysis', 'Long Range Plans']
// }

class Services extends Component {
  handleClick(e, id) {
    e.preventDefault();
    const tabs = document.querySelectorAll(".tabs li");
    tabs.forEach((tab) => {
      tab.classList.remove("is-active");
    });
    document.getElementById(id).classList.add("is-active");

    const contents = document.querySelectorAll("#tab-content div");
    contents.forEach((content) => {
      content.classList.remove("is-active");
    });
    const contents2 = document.querySelectorAll(
      "div[data-content='" + id + "']"
    );
    contents2.forEach((content) => {
      content.classList.add("is-active");
    });
  }

  render() {
    return (
      <div id="services" className="container" style={{ paddingTop: 5 }}>
        <div
          className="tabs-component"
          style={{ marginTop: 35, marginBottom: 55 }}
        >
          <h1
            className="has-text-centered is-size-2"
            style={{ paddingBottom: 25 }}
          >
            Services
          </h1>
          <div className="tabs is-centered">
            <ul>
              <li
                id="service-renewable"
                className="is-active"
                onClick={(e) => this.handleClick(e, "service-renewable")}
              >
                <a>
                  <span>Electrical Eng.</span>
                </a>
              </li>
              <li
                id="service-mechanical"
                className=""
                onClick={(e) => this.handleClick(e, "service-mechanical")}
              >
                <a>
                  <span>Mechanical Eng.</span>
                </a>
              </li>
              <li
                id="service-civil"
                className=""
                onClick={(e) => this.handleClick(e, "service-civil")}
              >
                <a>
                  <span>Civil Eng.</span>
                </a>
              </li>
              <li
                id="service-architecture"
                onClick={(e) => this.handleClick(e, "service-architecture")}
              >
                <a>
                  <span>Architecture</span>
                </a>
              </li>

              <li
                id="service-industrial"
                onClick={(e) => this.handleClick(e, "service-industrial")}
              >
                <a>
                  <span>Instrumentation & Controls</span>
                </a>
              </li>
              <li
                id="service-mining"
                onClick={(e) => this.handleClick(e, "service-mining")}
              >
                <a>
                  <span>Consulting</span>
                </a>
              </li>
            </ul>
          </div>
          <div id="tab-content">
            <ServiceEngineering />
            <ServiceMechanical />
            <ServiceCivil />
            <ServiceArchitecture />
            <ServiceControls />
            <ServiceConsulting />

            {/* <div className='container2' data-content="service-utilities">
          <img style={{float: 'left'}} width='500px'  src='https://images.unsplash.com/photo-1502637098811-fa9526d2b659?ixlib=rb-0.3.5&s=7ba990a6dc876d96386a5a96a85a3ad3&auto=format&fit=crop&w=889&q=80' />
           <p style={{float: 'right'}}>Utilities</p>
        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
