import React, { Component } from 'react';

class SectionTitle extends Component {

  render() {
    return (
      <div className="container" style={{marginTop: 20}}>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2 className="has-text-weight-semibold is-size-2" style={{color: '#118cff'}}>
                {this.props.children}
              </h2>
            </div>
        </div >
      </div >
    );
  };
};

export default SectionTitle;
