import React from "react";

function FullWidthImageHome({ image }) {
  return (
    <div
      className="full-width-image-container-home margin-top-0"
      style={{ backgroundImage: `url(${image})` }}
    />
  );
}

export default FullWidthImageHome;
