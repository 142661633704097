import React, { Component } from "react";
import Link from "gatsby-link";
import FAQ from "../FAQ/FAQ";

class ServiceArchitecture extends Component {
  render() {
    return (
      <div
        className="service"
        data-content="service-architecture"
        style={{ paddingTop: 5, width: "100%" }}
      >
        <div
          className="service-inner columns is-centered has-text-centered is-active"
          data-content="service-architecture"
          style={{ width: "100%", flexDirection: "row", display: "flex" }}
        >
          <div className="column is-active" data-content="service-architecture">
            <i
              className="fa fa-pencil-ruler"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <p className="is-size-3">Design</p>
            <ul>
              <li>Schematic Design, Code Review</li>
              <li>Design Development</li>
              <li>Permit Drawings</li>
              <li>Interior Design</li>
            </ul>
          </div>
          <div className="column is-active" data-content="service-architecture">
            <i
              className="fa fa-clipboard-list"
              style={{ fontSize: 40, marginBottom: 10 }}
            />
            <p className="is-size-3">Project Management</p>
            <ul>
              <li>Construction Documents</li>
              <li>Bid Packages</li>
              <li>Construction Administration</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceArchitecture;
