import React, { Component } from 'react';
import Link from 'gatsby-link';
import FAQ from '../FAQ/FAQ';


class ServiceCivil extends Component {
  render() {
    //<img />  TODO: Image on left, text on right
    return (
      <div className='service' data-content='service-civil' style={{paddingTop: 5, width: '100%'}}>
        <div className="service-inner columns is-centered has-text-centered" data-content='service-civil' style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
          <div className="column" data-content='service-civil'>
            <i className="fa fa-pencil-ruler" style={{fontSize: 40, marginBottom: 10}} />
            <p className="is-size-3">Design</p>
            <ul>
              <li>Site Grading and Drainage Plans</li>
              <li>Water, Wastewater, and Stormwater Utilities</li>
              <li>Roads and Parking Areas</li>
              <li>Structural Design for Site Improvements</li>
              <li>Water and Sewage Pump Stations</li>
              <li>Water Storage Reservoirs</li>
              {/*<li>Drainage Reports</li>
              <li>Basis of Design Reports</li>
              <li>Utility System Feasibility Studies</li>
              <li>Utility System Financial Studies</li>*/}
            </ul>
          </div>
          <div className="column" data-content='service-civil'>
            <i className="fa fa-tools" style={{fontSize: 40, marginBottom: 10}} />
            <p className="is-size-3">Planning</p>
            <ul>
              <li>Planning for Land Development Projects – Residential, Commercial, and Industrial</li>
              <li>Water System Master Planning</li>
              <li>Sanitary Sewer System Master Planning</li>
              <li>Grant and Loan Application Assistance</li>
              {/*<li>Project Cost Estimation</li>
              <li>Bidding Assistance</li>
              <li>Construction Monitoring</li>
              <li>Resident Engineer Services</li>*/}
            </ul>
          </div>
        </div>
        <div className="service-inner columns is-centered has-text-centered" data-content='service-civil' style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
          <div className="column" data-content='service-civil'>
            <i className="fa fa-project-diagram" style={{fontSize: 40, marginBottom: 10}} />
            <p className="is-size-3">Studies</p>
            <ul>
              <li>Drainage Reports</li>
              <li>Basis of Design Reports</li>
              <li>Utility System Feasibility Studies</li>
              <li>Utility System Financial Studies</li>
            </ul>
          </div>
          <div className="column" data-content='service-civil'>
            <i className="fa fa-clipboard-list" style={{fontSize: 40, marginBottom: 10}} />
            <p className="is-size-3">Project Management</p>
            <ul>
            <li>Project Cost Estimation</li>
            <li>Bidding Assistance</li>
            <li>Construction Monitoring</li>
            <li>Resident Engineer Services</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
};

export default ServiceCivil;
