import React, { Component } from 'react';
import Link from 'gatsby-link';

class HalfPic extends Component {

  render() {
    return (
      <div className="column is-full" style={{}}> {/*columns*/}
        <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center',height: 520 }}>
          <div>
            <p className="title is-4" style={{ color: '#118cff' }}>{this.props.question}</p>
          <div className="content" dangerouslySetInnerHTML={{__html: this.props.answer}} />
          </div>
        </div>
        <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0,display: 'flex', justifyContent: 'center', alignItems: 'center',height: 520}}>
          <img src={this.props.image} className='full-half-image'></img>
        </div>
      </div>
    );
  };
};

export default HalfPic;
