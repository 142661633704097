import React from "react";
import Helmet from "react-helmet";
// import SEO from "../components/SEO/SEO";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import config from "../../data/SiteConfig";

import Layout from "../components/layout";
import Testimonials from "../components/Testimonials/Testimonials";
import OurPromise from "../components/OurPromise/OurPromise";
import FAQ from "../components/FAQ/FAQ";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import FullWidthImage from "../components/FullWidthImage/FullWidthImage";
import Services from "../components/Services/Services";
import Content from "../components/Content/Content";
import ContactBanner from "../components/ContactBanner/ContactBanner";
import FeaturedProjects from "../components/FeaturedProjects/FeaturedProjects";
import CompanyOverview from "../components/CompanyOverview/CompanyOverview";
import ImageCarousel from "../components/Carousel/Carousel";

import team from "../../assets/team_members.json";

import ceo_photo from "../../assets/images/team/eric-sverdrup.jpg";
// import img from "../imgs/DJI_0038.webp"
// q_auto - dynmaic image quality
// f_auto - dynamic image type (ex, converts to another format on safari since webp isn't supported)

export default function Index(props) {
  return (
    <Layout location={props.location}>
      <div className="index-container underNavbar">
        {" "}
        {/* TODO: I dont think this class exists */}
        <Helmet htmlAttributes={{ lang: "en" }} title={config.siteTitle} />
        <ImageCarousel images={props.data.allMarkdownRemark.edges[0].node.frontmatter} />
        {/* <FeaturedProjects /> */}
        <CompanyOverview />
        {/* <Services /> */}
        <OurPromise />
        <section className="is-medium is-bold" style={{ backgroundColor: "#FFF" }}>
          <div className="hero-body">
            <div className="container">
              <h1 className="has-text-centered title" style={{ color: "#434a57" }}>
                Our Team
              </h1>
              <h2 className="subtitle" style={{ marginTop: 10, color: "#434a57" }}>
                <div>
                  <p>
                    Sverdrup Engineering Services provides engineering services for the renewable
                    energy sector, electrical power distribution, industrial automation and
                    wastewater treatment.
                  </p>
                  <br />

                  <p>
                    We work closely with our clients to deliver successful, cost-effective solutions
                    for their projects through our commitment to excellence, quality of service and
                    integrity. We can help you take your project from concept to completion, on time
                    and under budget.
                  </p>
                </div>
              </h2>
              <div className="is-flex is-horizontal-center padding-top-10 employee-pic">
                {/* TODO: Include multiple employees? */}
                {/* {
            team.map((member) => {
              return (
                <div className="employee-pic">
                  <figure className="image is-128x128">
                    <img className="is-rounded" src={require(`../imgs/team/${member.image}`)} />
                  </figure>
                  <p className="title is-4 padding-top-10">Eric Sverdrup, CEO</p>
                </div>
              );
            })
          } */}
                <figure className="image is-128x128">
                  {/* <img className="is-rounded" src='https://bulma.io/images/placeholders/128x128.png' /> */}
                  <img className="is-rounded" src={ceo_photo} />
                </figure>
                <p className="title is-4 padding-top-10">Eric Sverdrup, CEO</p>
                <Link to="/about-us#team" className="button is-link is-light">
                  Meet our team
                </Link>
              </div>
              {/* <Link to="/about-us#team" className="button is-link is-light">Meet our team</Link> */}
            </div>
          </div>
        </section>
        <ContactBanner />
      </div>
    </Layout>
  );
}

export function Head() {
  return (
    <>
      <link rel="icon" href="favicon.ico?v=2" type="image/x-icon" />
      <link rel="shortcut icon" href="favicon.ico?v=2" type="image/x-icon" />
    </>
  );
}

export const CarouselImageQuery = graphql`
  query CarouselImageQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(carousel-images)/.*.md$/" } }) {
      edges {
        node {
          frontmatter {
            image1
            image2
            image3
            image4
            image5
            image6
            image7
            image8
            image9
            image10
          }
        }
      }
    }
  }
`;
