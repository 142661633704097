import React, { Component } from 'react';
import Link from 'gatsby-link';
import FAQ from '../FAQ/FAQ';


class ServiceEngineering extends Component {
  render() {
    //<img />  TODO: Image on left, text on right
    return (
      <div className='service' data-content='service-mining' style={{paddingTop: 5, width: '100%'}}>
        <div className="service-inner columns is-centered has-text-centered" data-content='service-mining' style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
          <div className="column" data-content='service-mining'>
            <i className="fa fa-clipboard-list" style={{fontSize: 40, marginBottom: 10}} />
            <p className="is-size-3">Engineering Management Consulting</p>
            <ul>
              <li>Project Management</li>
              <li>Cost Analysis and Budget Development</li>
              <li>Schedule Generation and Monitoring</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
};

export default ServiceEngineering;
