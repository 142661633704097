import React, { useEffect } from "react";
import bulmaCarousel from "bulma-carousel";

import FullWidthImageHome from "../FullWidthImageHome/FullWidthImageHome";

export default function Carousel({ images }) {
  useEffect(() => {
    bulmaCarousel.attach("#carousel-demo", {
      slidesToScroll: 1,
      slidesToShow: 1,
      loop: true,
      // infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      duration: 800
    });
  }, []);

  return (
    <div id="carousel-demo" className="carousel">
      {Object.keys(images).sort((a, b) => a.localeCompare(b)).filter((image) => images[image] !== '').map((image, index) => {
        console.log(image)
        let image_url = images[image];
        if (image_url.startsWith('/static/')) {
          image_url = image_url.replace(/\/static/, '');
        }
        return (
          <div className={`item-${index + 1}`} key={`item-${index + 1}`}>
          <FullWidthImageHome image={image_url} />
        </div>
        )
      })}
    </div>
  );
}
