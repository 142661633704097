import React, { Component } from "react";
import Link from "gatsby-link";

class TwitterCard extends Component {
  render() {
    return (
      <div className="card" style={{ marginTop: 24, backgroundColor: this.props.backgroundColor }}>
        <div className="card-content">
          <div className="media">
            {/*
            <div className="media-left">
              <figure className="image is-48x48">
                <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
              </figure>
            </div> */}
            <div className="media-content">
              <p
                className={`title is-4 ${  this.props.fontColor}`}
                style={{ color: this.props.textColor }}
              >
                {this.props.title}
              </p>
            </div>
          </div>

          <div className={`content ${  this.props.fontColor || ""}`}>
            {this.props.content}
            {/* <br /> */}
            {/* <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time> */}
          </div>
        </div>
      </div>
    );
  }
}

export default TwitterCard;
