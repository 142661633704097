import React, { Component } from 'react';
import Link from 'gatsby-link';
import FAQ from '../FAQ/FAQ';


class ServiceEngineering extends Component {
  render() {
    //<img />  TODO: Image on left, text on right
    return (
      <div className='service is-active' data-content='service-renewable' style={{paddingTop: 5, width: '100%'}}>
        <div className="service-inner columns is-centered has-text-centered is-active" data-content='service-renewable' style={{width: '100%', flexDirection: 'row', display: 'flex'}}>
          <div className="column is-active" data-content='service-renewable'>
            <i className="fa fa-pencil-ruler" style={{fontSize: 40, marginBottom: 10}} />
            <p className="is-size-3">Design</p>
            <ul>
              <li>Single Line Diagrams</li>
              <li>Plan View Drawings</li>
              <li>Elementary/Interconnection Schematics</li>
              <li>Three Line Diagrams</li>
            </ul>
          </div>
          <div className="column is-active" data-content='service-renewable'>
            <i className="fa fa-project-diagram" style={{fontSize: 40, marginBottom: 10}} />
            <p className="is-size-3">Studies</p>
            <ul>
              <li>Short Circuit Analysis</li>
              <li>Arc Flash Analysis</li>
              <li>Device Protection Coordination Study</li>
              <li>Load Flow Analysis</li>
              <li>Long Range Plans</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
};

export default ServiceEngineering;
