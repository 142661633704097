import React, { Component } from "react";
import Link from "gatsby-link";
import ContactShort from "../ContactShort/ContactShort";

const content1 =
  "Our team of experienced engineers has worked on countless projects and knows what it takes to produce high quality designs.  We'll work with you to design exactly what you need.  ";
const content2 =
  "Our team has consistently delivered projects on schedule.  We know the potential problems that may arise and actively work to ensure everything goes as smoothly as possible.";
const content3 =
  "We have worked with dozens of companies to on both small and large-scale projects all across the United States.  Our customers love that we make the engineering process as easy as possible.";

class ContactBanner extends Component {
  render() {
    return (
      <section className="bd-tws-home section-light" style={{ paddingTop: 40, paddingBottom: 80 }}>
        <div className="container">
          <h1 className="has-text-centered title" style={{ paddingBottom: 25 }}>
            Work with us
          </h1>
          <div className="columns is-centered">
            {/* <div className="column is-4">

            </div> */}
            <div className="column has-text-centered">
              Contact us today to see how we can help you make your next architecture and
              engineering project a success.
              <ContactShort />
            </div>
            {/* <div className="column is-4">

            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default ContactBanner;
