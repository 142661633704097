import React, { Component } from "react";
import FAQCard from "../FAQCard/FAQCard";
import "./FAQ.css";

// import HalfPic from "../HalfPic/HalfPic";
import HalfPicLeft from "../HalfPicLeft/HalfPicLeft";
import HalfPicRight from "../HalfPicRight/HalfPicRight";

// import img1 from "../../imgs/american-public-power-association-419672-unsplash.jpg";
// import img2 from "../../imgs/american-public-power-association-430846-unsplash.jpg"

class FAQ extends Component {
  render() {
    let q1 = "Electrical Engineering";
    let a1 = `<div className="is-active" data-content="service-renewable">
       <p>Design:</p>
       <ul style={{display: 'list-item'}}>
         <li style={{display: 'list-item'}}>Single Line Diagrams</li>
         <li>Plan View Drawings</li>
         <li>Elementary/Interconnection Schematics</li>
         <li>Three Line Diagrams</li>
       </ul>
       <p>Studies:</p>
       <ul>
         <li>Short Circuit Analysis</li>
         <li>Arc Flash Analysis</li>
         <li>Device Protection Coordination Study</li>
         <li>Load Flow Analysis</li>
         <li>Long Range Plans</li>
       </ul>
    </div>`;
    let q2 = "Industrial Controls";
    let a2 = `<div data-content="service-industrial">
          <ul>
            <li>Panel Layout and Design</li>
            <li>Motor Controls</li>
            <li>Electrical Design, low, medium <span class="only-mobile"></span>and high voltage</li>
            <li>PLC programming <span class="only-mobile"></span>(Allen-Bradley, Siemens, Modicon, LabVIEW)</li>
            <li>HMI Design</li>
            <li>SCADA Design. Programming and <span class="only-mobile"></span>Implementation</li>
            <li>Control Block Diagrams</li>
            <li>Control Loop Drawings</li>
            <li>PLC Panel Drawings</li>
          </ul>
       </div>`;
    let q3 = "Instrumentation";
    let a3 = `<div data-content="service-commercial">
         <ul>
           <li>Instrumentation System Design</li>
           <li>Data Collection</li>
           <li>Database Management</li>
           <li>Wireless Communication and Networks</li>
         </ul>
       </div>`;
    let q4 = "Engineering Management Consulting";
    let a4 = `   <div data-content="service-mining">
         <ul>
           <li>Project Management</li>
           <li>Cost Analysis and Budget Development</li>
           <li>Schedule Generation and Monitoring</li>
         </ul>
       </div>`;
    return (
      <div style={{paddingTop: 12, paddingBottom: 12}}>
          <div className="columns is-multiline">
            {/* <div className="column is-6"> */}
              {/* <HalfPicLeft question={q1} answer={a1} image={img2} flip={false} style={{marginLeft: 0, marginRight: 0, height: 520,  paddingBottom: 12 }}/> */}
              {/* <HalfPicRight question={q2} answer={a2} image={img1} flip={true} style={{marginLeft: 0, marginRight: 0, height: 520, paddingTop: 12, paddingBottom: 12 }}/> */}
            {/* </div> */}
            {/* <div className="column is-6"> */}
              {/* <HalfPicLeft question={q3} answer={a3} image={img1} flip={false} style={{marginLeft: 0, marginRight: 0, height: 520, paddingTop: 12, paddingBottom: 12 }}/>
              <HalfPicRight question={q4} answer={a4}  image={img1} flip={true} style={{marginLeft: 0, marginRight: 0, height: 520, paddingTop: 12, }}/> */}
            {/* </div> */}


            <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0, paddingBottom: 0,display: 'flex', justifyContent: 'center', alignItems: 'center', height: 520}}>
              {/* <img src={this.props.image} className='full-half-image'></img> */}
              <div
                className="full-half-image"
                style={{ backgroundImage: `url(${img2})` }}
              ></div>
            </div>
            <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0,display: 'flex', justifyContent: 'center', alignItems: 'center', height: 520}}>
              <div>
                <p className="title is-4" style={{ color: '#118cff' }}>{q1}</p>
              <div className="content" dangerouslySetInnerHTML={{__html: a1}} />
              </div>
            </div>


            <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center',height: 520 }}>
              <div>
                <p className="title is-4" style={{ color: '#118cff' }}>{q2}</p>
              <div className="content" dangerouslySetInnerHTML={{__html: a2}} />
              </div>
            </div>
            <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0,display: 'flex', justifyContent: 'center', alignItems: 'center',height: 520}}>
              {/* <img src={img1} className='full-half-image'></img> */}
              <div
                className="full-half-image"
                style={{ backgroundImage: `url(${img1})` }}
              ></div>
            </div>

          <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0, paddingBottom: 0,display: 'flex', justifyContent: 'center', alignItems: 'center', height: 520}}>
            {/* <img src={this.props.image} className='full-half-image'></img> */}
            <div
              className="full-half-image"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
          </div>
          <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0,display: 'flex', justifyContent: 'center', alignItems: 'center', height: 520}}>
            <div>
              <p className="title is-4" style={{ color: '#118cff' }}>{q3}</p>
            <div className="content" dangerouslySetInnerHTML={{__html: a3}} />
            </div>
          </div>


          <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center',height: 520 }}>
            <div>
              <p className="title is-4" style={{ color: '#118cff' }}>{q4}</p>
            <div className="content" dangerouslySetInnerHTML={{__html: a4}} />
            </div>
          </div>
          <div className="column is-half" style={{paddingLeft:0, paddingRight: 0, paddingTop: 0,paddingBottom: 0,display: 'flex', justifyContent: 'center', alignItems: 'center',height: 520}}>
            {/* <img src={img1} className='full-half-image'></img> */}
            <div
              className="full-half-image"
              style={{ backgroundImage: `url(${img1})` }}
            ></div>
          </div>

        </div>
      </div>
    );
  }
}

export default FAQ;
