import React, { Component } from "react";
import Link from "gatsby-link";
import ProjectCard from "../ProjectCard/ProjectCard";

const project1 = {
  title: "TEP HQ EV DC Fast Chargers",
  location: "Tucson, Arizona",
  excerpt:
    "Engineering design and construction support for two Enel X 175KW DC fast-charging stations. The chargers can recharge the average EV in less than an hour and are part of Tucson Electric Power’s (TEP) commitment to promote the use of clean transportation. The chargers are located…",
  path: "/projects/tep-hq-ev-dc-fast-chargers",
  thumbnail: "/assets/pic1.jpg"
};

const project2 = {
  title: "Raptor Ridge Single Axis Tracker Solar Project",
  location: "Tucson, Arizona",
  excerpt:
    "Challenge: Tucson Electric Power (TEP) requested a state-of-the-art solar facility design incorporating lessons learned from TEP’s extensive renewable experience while utilizing the most reliable and cost-efficient equipment available in the market. In the past TEP…",
  path: "/projects/raptor-ridge-single-axis-tracker-solar-project",
  thumbnail: "/assets/4-cropped.jpg"
};

const project3 = {
  title: "SKIC 10MVA Substation and 35kV Power Line",
  location: "Fresno, CA",
  excerpt:
    "Algonquin hired Sverdrup Engineering Services for Civil, Electrical, and Structural design on a fast track 10MVA substation and 1.5 miles of 34.5kV distribution line to support solar PV facility in PG&E territory. Design started in February and ended in May with equipment…",
  path: "/projects/skic-10-mva-substation-and-35-k-v-power-line",
  thumbnail: "/assets/skic10-overview2-cropped.jpg"
};

class FeaturedProjects extends Component {
  render() {
    return (
      <section className="bd-tws-home section-light" style={{ paddingTop: 40, paddingBottom: 80 }}>
        <div className="container">
          <h1 className="has-text-centered is-size-2" style={{ paddingBottom: 25 }}>
            Featured Projects
          </h1>
          <div className="columns">
            <div className="column is-4">
              <ProjectCard project={project2} homepage />
            </div>
            <div className="column is-4">
              <ProjectCard project={project1} homepage />
            </div>
            <div className="column is-4">
              <ProjectCard project={project3} homepage />
            </div>
          </div>
        </div>
        <div className="is-flex is-horizontal-center padding-top-40">
          <Link to="/projects" className="button is-link">
            View all projects
          </Link>
        </div>
      </section>
    );
  }
}

export default FeaturedProjects;
