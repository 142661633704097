
import React, { Component } from 'react';
import Link from 'gatsby-link';
import TwitterCard from '../TwitterCard/TwitterCard';

class Testimonials extends Component {

  render() {
    return (
      <section className= "bd-tws-home">
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <TwitterCard />
              <TwitterCard />
            </div>
            <div className="column is-4">
              <TwitterCard />
              <TwitterCard />
            </div>
            <div className="column is-4">
              <TwitterCard />
              <TwitterCard />
            </div>
          </div>
        </div>
      </section>
    );
  };
};

export default Testimonials;